<style type="text/scss">
  // .arc-subhead {
  //   margin: 1rem 0 0.5rem;
  // }

  p {
    margin: 0 0 1rem;
  }

  // .img-l {
  //   display: block;
  //   float: left;
  //   margin: 0 0.5rem 0.5rem 0;

  //   @media screen and (max-width: 520px) {
  //     float: none;
  //     margin: 0 auto 0.5rem;
  //   }
  // }

  .img-r {
    display: block;
    float: right;
    margin: 0 0 0.5rem 0.5rem;

    @media screen and (max-width: 520px) {
      float: none;
      margin: 0 auto 0.5rem;
    }
  }

  .img-c {
    display: block;
    margin: 0 auto 1rem;
  }
</style>

<img src="/img/cases/bms-1.jpg" alt="" class="img-r" />
<p>
  BioMarker Strategies has developed SnapPath&reg;, the only cancer diagnostics
  system that automates and standardizes functional ex vivo profiling of live
  solid tumor cells from fresh biopsies or other 2 fresh, unfixed samples such
  as xenografts or tumorgrafts. SnapPath&reg; can help guide cancer drug
  development and treatment selection. SnapPath&reg; is unique in automating and
  standardizing functional profiling of live solid tumor cells from fresh,
  unfixed tissue samples – and enables the generation of PathMAP&reg; Functional
  Signaling Profiles.
</p>

<p>
  With the ongoing support of the National Cancer Institute (NCI), BioMarker
  Strategies is continuing development of PathMAP&reg;. PathMAP&reg; Functional
  Signaling Profiles represent a new class of biomarker tests, which are based
  on the dynamic and predictive signaling information available only from live
  cells. They are highly predictive of individual tumor response to treatment
  and are useful in identifying and understanding mechanisms of acquired
  resistance. BioMarker Strategies also believes that PathMAP&reg; Functional
  Signaling Profiles will prove highly predictive of individual patient response
  to immunotherapies and combinations of immunotherapies with targeted
  therapies.
</p>

<img src="/img/cases/bms-2.jpg" alt="" class="img-c" />
