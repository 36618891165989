<style type="text/scss">
  // .arc-subhead {
  //   margin: 1rem 0 0.5rem;
  // }

  p {
    margin: 0 0 1rem;
  }

  .img-l {
    display: block;
    float: left;
    margin: 0 0.5rem 0.5rem 0;

    @media screen and (max-width: 520px) {
      float: none;
      margin: 0 auto 0.5rem;
    }
  }

  .img-r {
    display: block;
    float: right;
    margin: 0 0 0.5rem 0.5rem;

    @media screen and (max-width: 520px) {
      float: none;
      margin: 0 auto 0.5rem;
    }
  }

  // .img-c {
  //   display: block;
  //   margin: 0 auto 1rem;
  // }
</style>

<img src="/img/cases/hbi-1.jpg" alt="" class="img-r" />
<p>
  Hawaii Biotech (HBI) is the oldest and largest biotech company in Hawaii,
  founded in 1982 by four University of Hawaii professors.
</p>

<p>
  HBI researches and develops prophylactic vaccines for infectious diseases. The
  company utilizes a vaccine platform technology to produce recombinant subunit
  protein vaccines that optimizes the quality of recombinant protein and its
  impact on immunogenicity. This proprietary platform develops vaccines for a
  variety of diseases, including Zika, West Nile, Dengue, tick borne
  encephalitis, Ebola and other hemorrhagic fever viruses, and malaria.
</p>

<p>
  HBI has a long record of winning and performing under major grants from NIAID,
  BARDA, DTRA, US Army, and other sources.
</p>

<img src="/img/cases/hbi-2.jpg" alt="" class="img-l" />
<p>
  In late 2016, HBI had the opportunity to secure a new primary lab facility.
  Financing for the construction was not readily available, and the company did
  not want to incur the dilution from taking in additional equity investment.
  ARC allowed HBI to capitalize the stable, predictable cash flow of its large
  grant and contract portfolio to fund prompt completion of its new lab and
  office space.
</p>

<p>
  Since then, HBI has used its credit line with ARC to buffer the chunky and
  intermittent disbursements associated with large grants and contracts
  simplifying HBI's cash management needs.
</p>
