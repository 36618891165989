
<p>
  IQ-Analog Corporation is a leading developer of wideband transceiver products
  servicing defense markets for advanced wireless communications and radar
  systems, and commercial markets with 5G semiconductor mixed-signal ASIC
  antenna processor technology. With over a decade of research and development,
  our novel approach to high speed data conversion, using temporal-domain signal
  processing, is at the core of a new class of integrated circuits performing
  digital antenna processing with unprecedented performance and power
  efficiency. The patented technology reduces chip area and power consumption by
  a factor of four or more relative to state-of-the-art commercial components
  provided by vendors such as Xilinx, Intel, Analog Devices, Texas Instruments,
  and Broadcom.
</p>

<p>
  Formed in 2004 by a former engineering director at Broadcom, IQ-Analog is
  comprised of industry veterans with heritage from major U.S. chip suppliers.
  The company is 80% employee owned and offers a 100% U.S. based design team
  with no international dependencies and top secret clearance access. Customers
  include DARPA, Lockheed Martin, NXP, Broadcom, and others.
</p>

<img src="/img/cases/iqa-1.jpg" alt="" class="img-c" />

<p>
  The company’s focus since inception has been to look beyond the state of the
  art in high speed data converter technology. Research and development has been
  funded primarily through IP licensing of derivative data converter core
  technologies and funding from DARPA. The company holds 31 patents including 22
  issued US patents protecting its unique product technology.
</p>

<img src="/img/cases/iqa-2.jpg" alt="" class="img-c" />

<p>
  IQ-Analog is the sole source of digital antenna processors for the defense /
  aerospace market servicing next generation radar, communications, and
  electronic warfare. The company has applied its revolutionary,
  patent-protected technology to the first wave of 5G wireless infrastructure
  and is partnering with industry leaders to deliver the next wave of processors
  with embedded data conversion. IQ-Analog data conversion systems leverage TPWQ
  that operate with 8X faster cores and offer 4X lower power with 14X smaller
  die area. Since the build cost of semiconductors is proportional to die area,
  this area savings converts directly to an overall 93% cost savings.
</p>

<div class="clearfix">
  <img src="/img/cases/iqa-3.jpg" alt="" class="img-l pair" />
  <img src="/img/cases/iqa-4.jpg" alt="" class="img-l pair" />
</div>

<style type="text/scss">
  // .arc-subhead {
  //   margin: 1rem 0 0.5rem;
  // }

  p {
    margin: 0 0 1rem;
  }

  .img-l {
    display: block;
    float: left;
    margin: 0 0.5rem 0.5rem 0;

    &.pair {
      max-width: calc(49% - 1rem);
    }

    @media screen and (max-width: 520px) {
      float: none;
      margin: 0 auto 0.5rem;
    }
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  // .img-r {
  //   display: block;
  //   float: right;
  //   margin: 0 0 0.5rem 0.5rem;

  //   @media screen and (max-width: 520px) {
  //     float: none;
  //     margin: 0 auto 0.5rem;
  //   }
  // }

  .img-c {
    display: block;
    margin: 0 auto 1rem;
  }
</style>