<script>
  import { navTo } from "../stores/route-store.js";
</script>

<style type="text/scss">
  .tile img {
    display: block;
    margin: 0 auto;
  }

  .arc-subhead {
    margin: 0.5rem 0;
  }
</style>

<section class="section" style="padding-top:1rem;">
  <div class="container">
    <div class="arc-head">Case Studies</div>

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child box">
          <a href="/" on:click={navTo} data-dest="/case/hnu-photonics">
            <img src="/img/cases/logo-hnu-photonics.jpg" alt="" />
          </a>
          <div class="arc-subhead">H Nu Photonics</div>
          HNu Photonics is a science and technology company creating
          cutting-edge technologies and transforming innovative ideas into
          state-of-the-art products with commercial, scientific and military
          applications. The focus of the company is renewable energy, defense
          photonics, specialized optics, and medical imaging technologies.
          <p>
            <a href="/" on:click={navTo} data-dest="/case/hnu-photonics">
              More &gt;&gt;
            </a>
          </p>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box">
          <a href="/" on:click={navTo} data-dest="/case/hawaii-bio">
            <img src="/img/cases/logo-hawaii-bio.jpg" alt="" />
          </a>
          <div class="arc-subhead">Hawaii Biotech</div>
          Hawaii Biotech, Inc., the oldest and largest biotech company in
          Hawaii, focuses on the research and development of vaccines for
          established and emerging infectious diseases.
          <p>
            <a href="/" on:click={navTo} data-dest="/case/hawaii-bio">
              More &gt;&gt;
            </a>
          </p>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box">
          <a href="/" on:click={navTo} data-dest="/case/iq-analog">
            <img src="/img/cases/logo-iq-analog.jpg" alt="" />
          </a>
          <div class="arc-subhead">IQ-Analog Corporation</div>
          IQ-Analog Corporation is a U.S. domestic fabless semiconductor company
          focused on delivering antenna processor integrated circuits with
          unmatched performance in high speed analog / digital data conversion.
          <p>
            <a href="/" on:click={navTo} data-dest="/case/iq-analog">
              More &gt;&gt;
            </a>
          </p>
        </div>
      </div>
    </div>

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child box">
          <a href="/" on:click={navTo} data-dest="/case/biomarker-strategies">
            <img src="/img/cases/logo-bms.jpg" alt="" />
          </a>
          <div class="arc-subhead">Biomarker Strategies</div>
          BioMarker Strategies has developed the proprietary and recently
          patented SnapPath&reg; Cancer Diagnostics System for predictive tests
          to guide targeted drug development and treatment selection for
          patients with solid tumor malignancies.
          <p>
            <a href="/" on:click={navTo} data-dest="/case/biomarker-strategies">
              More &gt;&gt;
            </a>
          </p>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box">
          <a href="/" on:click={navTo} data-dest="/case/kineta">
            <img src="/img/cases/logo-kineta.jpg" alt="" />
          </a>
          <div class="arc-subhead">Kineta, Inc.</div>
          Kineta fills a void in the biopharmaceutical industry by efficiently
          advancing therapies from discovery to clinical proof of concept.
          <p>
            <a href="/" on:click={navTo} data-dest="/case/kineta">
              More &gt;&gt;
            </a>
          </p>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box" />
      </div>
    </div>

  </div>

</section>
