<style type="text/scss">
  .summary {
    text-align: center;
    margin: 2rem 0;

    @media screen and (max-width: 450px) {
      margin: 2rem 0 0;
    }
  }
  .summary-img {
    text-align: center;
    margin: 0;

    img {
      display: inline-block;
      width: 186px;
      height: auto;
    }

    @media screen and (max-width: 450px) {
      display: none;
    }
  }
</style>

<div class="container">
  <div class="summary">
    Working capital for companies performing research contracts under
    <br />
    Federal Agencies &#8226; NIH &#8226; NCI &#8226; DOD &#8226; DOE &#8226;
    Universities &#8226; Research Institutions
  </div>
  <div class="summary-img">
    <img src="/img/sq1.jpg" alt="science" />
    <img src="/img/sq2.jpg" alt="science" />
    <img src="/img/sq3.jpg" alt="science" />
    <img src="/img/sq4.jpg" alt="science" />
  </div>
</div>
